import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { from } from 'rxjs';
import { User } from '../models/user';

export const L_STORAGE_AUTH_KEY = 'CRM_AUTH_TOKEN';
export const L_STORAGE_USER_KEY = 'CRM_USER_DATA';
export const L_STORAGE_USER_LOGIN_KEY = 'CRM_USER_LOGIN';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authState$ = new BehaviorSubject(null);
  public selectedDepartment$ = new Subject();
  public alerts: boolean;

  constructor(
    private http: HttpClient,
    private auth: AngularFireAuth,
    private router: Router
    ) {
    this.alerts = true;
  }

  get notificationsAlerts(): boolean {
    return this.alerts;
  }

  set notificationsAlerts(value: boolean) {
    this.alerts = value;
  }

  get authToken(): string {
    if (localStorage.getItem(L_STORAGE_AUTH_KEY)) {
      return JSON.parse(localStorage.getItem(L_STORAGE_AUTH_KEY));
    }
  }

  get userAccount() {
    if (localStorage.getItem(L_STORAGE_USER_KEY)) {
      const userData = JSON.parse(localStorage.getItem(L_STORAGE_USER_KEY));
      return new User(userData);
    }
  }

  get authenticated(): boolean {
    return !!this.authToken;
  }

  get organization(): string {
    return this.userAccount.organization;
  }

  get originalOrganizationId(): string {
    return this.userAccount.organization;
  }

  setTokenToLocalStorage(token: string, callback) {
    localStorage.setItem(L_STORAGE_AUTH_KEY, JSON.stringify(token));
  }

  setUserLoginToLocalStorage(logins) {
    localStorage.setItem(L_STORAGE_USER_LOGIN_KEY, JSON.stringify(logins));
  }

  setUserDataToLocalStorage(data) {
    localStorage.setItem(L_STORAGE_USER_KEY, JSON.stringify(data));
  }

  get authToLogin() {
    if (localStorage.getItem(L_STORAGE_USER_LOGIN_KEY)) {
      return JSON.parse(localStorage.getItem(L_STORAGE_USER_LOGIN_KEY));
    }
  }

  logIn(email, password): Observable<any> {
    return from(this.auth.signInWithEmailAndPassword(email, password))
      .pipe(map(res => {
        this.setTokenToLocalStorage(res.user.refreshToken, () => {});
        this.authState$.next(res.user);
        return res.user;
      }));
  }

  logOut(): void {
    localStorage.removeItem(L_STORAGE_AUTH_KEY);
    localStorage.removeItem(L_STORAGE_USER_KEY);

    this.auth.signOut().then(() => {
      this.authState$.next(null);
      this.router.navigate(['/auth/login']);
    }).catch((error) => {
      // An error happened.
    });
  }
}
