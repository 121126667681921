export class User {

  readonly firstname: string;
  readonly lastname: string;

  get fullName(): string {
    return [
      this.firstname,
      this.lastname
    ].join(' ')
  }

  readonly organization: string;
  readonly role: string;

  constructor({ firstname, lastname, organization, role }) {
    this.firstname = firstname;
    this.lastname = lastname;
    this.organization = organization;
    this.role = role;
  }
}
